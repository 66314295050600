import { Menu, Transition } from '@headlessui/react'
import { Fragment, useEffect, useRef, useState } from 'react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { addVat, getVat, UKPriceFormat } from '../config';
import GenerateQuotePdf from './GeneratePdf';
import GenerateProformaPdf from './GenerateProformaPdf';
import GenerateOrderPdf from './GenerateOrderPdf';
import GeneratePdf from './GeneratePdf';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Tooltip } from 'react-tooltip';

export default function DropDownMenu(props) {

    return (
        <div className="relative text-right z-10">
            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <Menu.Button className="tooltip-download btn flex items-center ml-auto"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                    </svg>
                        <span className='ml-2'>Downloads</span>
                        <ChevronDownIcon
                            className="ml-2 -mr-1 h-5 w-5"
                            aria-hidden="true"
                        />
                        <Tooltip anchorSelect=".tooltip-download" place="top" className="tooltip">
                            Please update the revision before downloading the quote or proforma.
                        </Tooltip>
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute right-0 mt-2 w-44 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="px-1 py-1 ">
                            {props.projectType !== "order" &&
                                <>
                                    <Menu.Item>
                                        <PDFDownloadLink
                                            document={
                                                <GeneratePdf
                                                    title="Sales Quote"
                                                    type="quote"
                                                    projectData={props.projectData}
                                                    products={props.products}
                                                    extras={props.extras}
                                                    vat={UKPriceFormat(getVat(props.totalNoCommission, 20))}
                                                    total={UKPriceFormat(props.totalNoCommission)}
                                                    totalWithVat={UKPriceFormat(addVat(props.totalNoCommission, 20))}
                                                    deposit={props.totalNoCommission > 5000 ? UKPriceFormat(addVat(props.totalNoCommission, 20) / 2) : false}
                                                    productsGrouped={props.productsGrouped}
                                                    hasCommission={false}

                                                />}
                                            fileName={props.projectData.project_number + "-quote"}>
                                            {({ loading }) => loading ? (<span className='btn bg-white w-full text-left justify-start hover:bg-slate-50 block'>Loading...</span>) : (
                                                <button className='btn bg-white w-full text-left justify-start hover:bg-slate-50'>
                                                    Sales Quote
                                                </button>
                                            )}
                                        </PDFDownloadLink>
                                    </Menu.Item>
                                    {props.hasCommission &&
                                        <Menu.Item>
                                            <PDFDownloadLink
                                                document={
                                                    <GeneratePdf
                                                        title="Sales Quote"
                                                        type="quote"
                                                        projectData={props.projectData}
                                                        products={props.products}
                                                        extras={props.extras}
                                                        vat={UKPriceFormat(getVat(props.total, 20))}
                                                        total={UKPriceFormat(props.total)}
                                                        totalWithVat={UKPriceFormat(addVat(props.total, 20))}
                                                        deposit={props.total > 5000 ? UKPriceFormat(addVat(props.total, 20) / 2) : false}
                                                        productsGrouped={props.productsGrouped}
                                                        hasCommission={true}

                                                    />}
                                                fileName={props.projectData.project_number + "-c-quote"}>
                                                {({ loading }) => loading ? (<span className='btn bg-white w-full text-left justify-start hover:bg-slate-50 block'>Loading...</span>) : (
                                                    <button className='btn bg-white w-full text-left justify-start hover:bg-slate-50'>
                                                        Sales Quote with Commission ({props.projectData.commission}%)
                                                    </button>
                                                )}
                                            </PDFDownloadLink>
                                        </Menu.Item>
                                    }
                                </>
                            }
                            {props.projectType === "order" &&
                                <>
                                    <Menu.Item>
                                        <PDFDownloadLink
                                            document={
                                                <GeneratePdf
                                                    title="Proforma Invoice"
                                                    type="proforma"
                                                    projectData={props.projectData}
                                                    products={props.products}
                                                    extras={props.extras}
                                                    vat={UKPriceFormat(getVat(props.total, 20))}
                                                    total={UKPriceFormat(props.total)}
                                                    totalWithVat={UKPriceFormat(addVat(props.total, 20))}
                                                    productsGrouped={props.productsGrouped}
                                                    hasCommission={false}

                                                />}
                                            fileName={props.projectData.project_number + "-invoice"}>
                                            {({ loading }) => loading ? (<span className='btn bg-white w-full text-left justify-start hover:bg-slate-50 block'>Loading...</span>) : (
                                                <button className='btn bg-white w-full text-left justify-start hover:bg-slate-50'>
                                                    Proforma Invoice
                                                </button>
                                            )}
                                        </PDFDownloadLink>
                                    </Menu.Item>
                                    {props.hasCommission &&
                                        <Menu.Item>
                                            <PDFDownloadLink
                                                document={
                                                    <GeneratePdf
                                                        title="Proforma Invoice"
                                                        type="proforma"
                                                        projectData={props.projectData}
                                                        products={props.products}
                                                        extras={props.extras}
                                                        vat={UKPriceFormat(getVat(props.total, 20))}
                                                        total={UKPriceFormat(props.total)}
                                                        totalWithVat={UKPriceFormat(addVat(props.total, 20))}
                                                        productsGrouped={props.productsGrouped}
                                                        hasCommission={true}

                                                    />}
                                                fileName={props.projectData.project_number + "-c-invoice"}>
                                                {({ loading }) => loading ? (<span className='btn bg-white w-full text-left justify-start hover:bg-slate-50 block'>Loading...</span>) : (
                                                    <button className='btn bg-white w-full text-left justify-start hover:bg-slate-50'>
                                                        Proforma Invoice with Commission ({props.projectData.commission}%)
                                                    </button>
                                                )}
                                            </PDFDownloadLink>
                                        </Menu.Item>
                                    }
                                </>
                            }
                            {/* <Menu.Item>
                                <PDFDownloadLink
                                    document={
                                        <GenerateOrderPdf
                                            title="Order"
                                            type="order"
                                            projectData={props.projectData}
                                            products={props.products}
                                            extras={props.extras}
                                            vat={getVat(props.totalNoMarkup, 20)}
                                            total={props.totalNoMarkup}
                                            totalWithVat={addVat(props.totalNoMarkup, 20)}
                                            productsGrouped={props.productsGrouped}
                                            
                                        />}
                                    fileName={props.projectData.project_number + "-order"}>
                                    {({ loading }) => loading ? (<span className='btn bg-white w-full text-left justify-start hover:bg-slate-50 block'>Loading...</span>) : (
                                        <button className='btn bg-white w-full text-left justify-start hover:bg-slate-50'>
                                            Supplier Order
                                        </button>
                                    )}
                                </PDFDownloadLink>
                            </Menu.Item> */}
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    )
}